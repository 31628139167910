<template>
  <div class="page404">
    <img class="img" @click="router.push('/')" src="~@assets/img/404_pc.jpg" alt="">
  </div>
</template>
<script lang="ts">
import { ref, defineComponent, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "404",
  setup(props, content) {
    const store = useStore();
    const router = useRouter();
    return {
      router,
    };
  },
});
</script>
<style lang="less" scoped>
.page404 {
  text-align: center;
  padding-top: 30px;
}
.img {
  width: 800px;
  max-width: 100%;
  border-radius: 100px;
  cursor: pointer;
}
@media (max-width: 600px) {
  .page404 {
    padding: 5px;
  }
  .img {
    max-width: 100%;
    border-radius: 5px;
  }
}
</style>