
import { ref, defineComponent, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "404",
  setup(props, content) {
    const store = useStore();
    const router = useRouter();
    return {
      router,
    };
  },
});
